@charset "UTF-8";

@font-face {
  font-family: "cse";
  src:url("../fonts/cse.eot");
  src:url("../fonts/cse.eot?#iefix") format("embedded-opentype"),
    url("../fonts/cse.woff") format("woff"),
    url("../fonts/cse.ttf") format("truetype"),
    url("../fonts/cse.svg#cse") format("svg");
  font-weight: normal;
  font-style: normal;
}

@mixin icon {
  font-family: "cse" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
}

[data-icon]:before {
  @include icon;
  content: attr(data-icon);
}

[class^="icon-"],
[class*=" icon-"] {
  position: relative;
  line-height: 0;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  @include icon;
}

.icon-close:before {
  content: "\61";
}
.icon-info:before {
  content: "\62";
}
.icon-chevron-b:before {
  content: "\63";
}
.icon-arrow:before {
  content: "\64";
}
.icon-info-1:before {
  content: "\65";
}
