@mixin breakpoint($class) {
  @if $class == xs {
    @media (max-width: 575px) { @content; }
  }

  @else if $class == sm {
    @media (min-width: 576px) { @content; }
  }

  @else if $class == exsm {
    @media (min-width: 576px) and (max-width: 767px) { @content; }
  }

  @else if $class == ltmd {
    @media (max-width: 767px) { @content; }
  }

  @else if $class == md {
    @media (min-width: 768px) { @content; }
  }

  @else if $class == exmd {
    @media (min-width: 768px) and (max-width: 995px) { @content; }
  }

  @else if $class == ltlg {
    @media (max-width: 995px) { @content; }
  }

  @else if $class == lg {
    @media (min-width: 996px) { @content; }
  }

  @else if $class == exlg {
    @media (min-width: 996px) and (max-width: 1199px) { @content; }
  }

  @else if $class == ltxl {
    @media (max-width: 1199px) { @content; }
  }

  @else if $class == xl {
    @media (min-width: 1200px) { @content; }
  }

  @else if $class == exxl {
    @media (min-width: 1200px) and (max-width: 1399px) { @content; }
  }

  @else if $class == ltxxl {
    @media (max-width: 1399px) { @content; }
  }

  @else if $class == xxl {
    @media (min-width: 1400px) { @content; }
  }
}
