// Forms

input,
textarea {
  &::-webkit-input-placeholder {
    color: $border-grey;
  }
  &::-moz-placeholder {
    color: $border-grey;
  }
  &:-ms-input-placeholder {
    color: $border-grey;
  }
  &:-moz-placeholder {
    color: $border-grey;
  }
}

.form-info {
  font-size: 24px;
  margin-bottom: 16px;
}

.form-block,
.form-block-checkboxes,
.form-block-radios {
  margin-bottom: 16px;
  position: relative;

  &.invalid {
    .form-error {
      opacity: 1;
    }

    label {
      color: $red;
    }
  }

  &:last-child {
    margin: 0;
  }

  &.focus,
  &.full {
    .form-block-chars {
      opacity: 1;
    }
  }
}

.form-error {
  color: $red;
  font-size: 13px;
  font-weight: 300;
  opacity: 0;
  padding-left: 16px;
}

.form-block-chars {
  font-weight: 300;
  font-size: 13px;
  color: $border-grey;
  margin-left: auto;
  opacity: 0;
}

// Bottom section

.form-bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;

  .button + .button {
    margin-left: 24px;
  }

  & + .form-block-checkboxes {
    margin-top: 32px;
  }
}

// Label

label {
  display: block;
  font-size: 16px;
  font-weight: 400;
  color: $near-black;
  cursor: pointer;
  transition: $transition;
  margin-bottom: 8px;

  .required {
    color: $red;
  }
}

// Inputs

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea,
.selectyummy .selectyummy-select,
.input-wrapper {
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  border: 0;
  border-bottom: 2px solid $border-grey;
  border-radius: 4px 4px 0 0;
  background: $bg-grey;
  color: $near-black;
  transition: $transition;

  &.invalid,
  &:invalid,
  .invalid & {
    border-color: $red;
  }

  &[disabled] {
    border-color: $bg-grey;
    color: $border-grey;
  }
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="number"], {
  height: 48px;
  padding: 0 16px;
}

textarea {
  height: 150px;
  padding: 16px;
}

// --- Selects ---

.base-select {
  display: none;
}

.selectyummy {
  position: relative;

  .selectyummy-select {
    padding: 16px;
    height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;

    &::after {
      @include icon;
      content: "\63";
      font-size: 9px;
      margin-left: 16px;
      transition: $transition;
    }
  }

  &.active .selectyummy-select {
    &::after {
      transform: rotate(180deg);
    }
  }

  .selectyummy-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    list-style: none;
    display: none;
    z-index: 2;
    background: $bg-grey;
    max-height: 200px;
    overflow-y: auto;

    li {
      display: block;
      padding: 16px;
      border: 1px solid transparent;
      font-size: 16px;
      height: 56px;
      cursor: pointer;
      position: relative;

      & + li {
        margin-top: -1px;
      }

      &:hover,
      &.hover,
      &.active {
        border-color: $dark-blue;
        z-index: 1;
      }

      &.disabled {
        user-select: none;
        -webkit-user-select: none;
        pointer-events: none;
        color: rgba($black, .5);
      }
    }
  }
}

.form-block-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 4px;
}

// Wrappers

.button-wrapper {
  position: relative;
  padding: 0;
  border: 0;

  input[type="submit"] {
    @extend .button;
    height: 50px;
    width: 208px;
    padding: 0 56px 0 32px;
    justify-content: flex-start;

    &:hover {
      background: $black;
      color: $white;
    }
  }

  i {
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    right: 24px;
    font-size: 23px;
    pointer-events: none;
  }

  &:hover {
    color: $white;
  }
}

.input-wrapper {
  display: flex;
  align-items: center;
  padding: 15px 16px;
  position: relative;

  label {
    position: absolute;
    pointer-events: none;
    top: 15px;
    margin: 0;
  }

  i {
    margin-left: 8px;
    font-size: 17px;
    opacity: 0;
  }

  input {
    border: 0;
    background: none;
    padding: 0;
    height: 24px;
    position: relative;
    transition: $transition;
    top: 0;

    &:focus,
    .focus &,
    .full & {
      top: 8px;

      & + label {
        font-size: 12px;
        top: 8px;
      }
    }
  }

  .focus:not(.invalid) & {
    border-color: $dark-blue;

    label {
      color: $dark-blue;
    }
  }

  .invalid & {
    i {
      opacity: 1;
    }
  }
}
