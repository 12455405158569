@import url('https://fonts.googleapis.com/css?family=Ubuntu:300,400,500&display=swap');
@import "fonts";
@import "vars";
@import "mixins";
@import "grid";

/* Reset */

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:focus{
  outline: none;
  box-shadow: none;
}

hr {
  clear: both;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  text-decoration: none;
}

a,
input[type="submit"],
button {
  transition: $transition;
  cursor: pointer;
}

input[type="submit"],
button {
  border: 0;
  background: none;
}

input,
textarea,
button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

img {
  max-width: calc(100% + .1px);
  height: auto;
}

/* General */

html, body {
  &.nooverflow {
    overflow: hidden !important;
  }
}

body {
  background: $white;
  color: $black;
  font: 16px/1.5 $font;
  min-width: 320px;
}

p, ul, ol, blockquote {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 40px;
}

h1, .h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.2;
}

h2, .h2 {
  font-size: 40px;
  line-height: 1.3;
}

h3, .h3 {
  font-size: 24px;
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 18px;
}

.ul {
  li {
    position: relative;
    padding-left: 21px;

    & + li {
      margin-top: 8px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 13px;
      left: 0;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $black;
      flex-shrink: 0;
    }
  }
}

ol {
  margin-left: 24px;
}

.img-wrapper {
  font-size: 0;
  line-height: 0;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-width: none;
  }
}

// Links, buttons

.regular-link {
  color: $black;
  font-weight: 500;
  background: linear-gradient(to right, $black 0%, $black 100%) no-repeat 0px 100% / 100% 1px, linear-gradient(to right, $turquois 0%, $turquois 100%) no-repeat 0px 100% / 0 100%;
  transition: $transition2;

  &:hover {
    color: $black;
    background-size: 100% 1px, 100% 100%;
  }
}

.link {
  color: $black;
  background: linear-gradient(to right, $black 0%, $black 100%) no-repeat 0px 100% / 100% 1px;
  transition: all .2s ease-out;

  span {
    background: linear-gradient(to right, $red 0%, $red 100%) no-repeat 0px 100% / 0 100%;
    transition: $transition2;
    line-height: inherit;
  }

  &:hover {
    color: $black;

    span {
      background-size: 100% 100%;
    }
  }
}

.link-icon {
  display: inline-flex;
  align-items: center;

  i {
    margin-right: 8px;
    line-height: 0;

    &:last-child {
      margin: 0 0 0 8px;
    }
  }

  &:hover {
    color: $red;
  }
}

.button {
  transition: $transition2;
  overflow: hidden;
  position: relative;
  z-index: 1;
  font-weight: 300;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border: 1px solid $near-black;
  border-radius: 30px;
  background: $white;
  color: $black;

  &::before {
    content: '';
    display: block;
    background: $black;
    border-radius: 28px;
    height: calc(100% + 2px);
    width: calc(100% + 2px);
    position: absolute;
    left: -1px;
    top: -1px;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: $transition2;
    will-change: transform;
    z-index: -1;
  }

  &:hover,
  &.hover,
  &:focus-within {
    color: $white;

    &::before {
      transform: translate(0, 0);
    }
  }
}

.button-icon {
  @extend .button;

  i {
    margin-right: 16px;

    &:last-child {
      margin: 0 0 0 16px;
    }
  }
}

.buttons,
.links {
  &--horizontal {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: -16px;

    .link,
    .button {
      margin: 16px;
    }
  }

  &--vertical {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .link,
    .button {
      & + .link,
      & + .button {
        margin-top: 16px;
      }
    }
  }
}

// Boxes

.boxes-row {
  margin-bottom: -24px;

  .box {
    height: calc(100% - 24px);
    margin-bottom: 24px;
  }
}

.box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.box-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.box-img {
  @extend .img-wrapper;
}

.box-title {
  display: block;
}

a.box-title {
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    z-index: 2;
  }

  span {
    background: linear-gradient(to right, $red 0%, $red 100%) no-repeat 0px 100% / 0 100%;
    transition: $transition2;
    line-height: inherit;
  }

  &:hover {
    span {
      background-size: 100% 100%;
    }
  }
}



// --- Popup ---

body.nooverflow {
  overflow: hidden;
}

.popup-wrapper {
  position: fixed;
  display: none;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;

  .popup-overlay {
    background: rgba($near-black, .9);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90vh;
    height: 442px;
    max-width: 90%;
    background: $white;
    color: $near-black;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    text-align: center;
    padding: 0 24px;

    .popup-close {
      position: fixed;
      top: 48px;
      right: 48px;
      font-size: 28px;

      &:hover {
        color: $dark-blue;
      }
    }
  }
}



// --- Tooltip ---

.tooltip {
  position: absolute;
  right: calc(100% + 20px);
  top: 16px;
}

.tooltip-link {
  font-size: 17px;
}

.tooltip-bubble {
  opacity: 0;
  z-index: -1;
  transition: opacity .5s ease, z-index 1s ease;
  position: absolute;
  pointer-events: none;
  left: -13px;
  width: 280px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    transform: rotate(45deg);
    width: 8px;
    height: 8px;
    background: $near-black;
    left: 17px;
  }

  .tooltip-bubble-inner {
    padding: 16px;
    width: 100%;
    background: $near-black;
    color: $white;
    font-size: 14px;
  }
}

.tooltip:not(.tooltip--bottom) .tooltip-bubble {
  bottom: 100%;
  padding-bottom: 8px;

  &::after {
    bottom: 4px;
  }
}

.tooltip.tooltip--bottom .tooltip-bubble {
  top: 100%;
  padding-top: 8px;

  &::after {
    top: 4px;
  }
}

.tooltip.tooltip--fixed {
  .tooltip-bubble {
    position: fixed;
  }

  &:not(.tooltip--bottom) .tooltip-bubble {
    bottom: auto;
  }
}

.tooltip:not(.tooltip--onclick):hover .tooltip-bubble,
.tooltip.tooltip--onclick.active .tooltip-bubble {
  opacity: 1;
  z-index: 10;
  pointer-events: auto;
}



@import "form";

.logo {
  font-size: 0;
}

// --- Header ---

.header-top {
  padding: 32px 48px;

  @include breakpoint (ltlg) {
    padding: 32px 16px;
  }

  a {
    display: block;
  }
}

.hero {
  position: relative;
  margin-bottom: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @include breakpoint (lg) {
    height: 540px;
  }

  @include breakpoint (ltlg) {
    padding-top: 180px;
    margin-bottom: 112px;
  }

  .hero-img {
    @extend .img-wrapper;
    position: absolute;
    top: 0;
    left: 0;
    height: 496px;
    width: 100%;

    @include breakpoint (ltlg) {
      height: 304px;
    }
  }

  .box {
    background: linear-gradient(220.37deg, rgba($dark-blue, .9) 0%, rgba($dark-blue2, .9) 100%);
    box-shadow: 0 25px 100px rgba($black, 0.15);
    border-radius: 4px;
    max-width: 817px;
    padding: 76px 40px;

    @include breakpoint (ltlg) {
      padding: 40px;
    }

    h1 {
      color: $white;
      margin: 0;
    }
  }
}

// --- Footer ---

.footer {
  background: $bg-grey;
  padding: 0 0 24px;
  font-size: 16px;

  .col-lg-4 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include breakpoint (ltlg) {
      &.col-left {
        margin-bottom: 32px;
      }
    };
  }

  .logo {
    margin-bottom: 24px;

    @include breakpoint (lg) {
      margin-bottom: 48px;
    };

    img {
      max-width: 206px;
    }
  }

  .footer-nav--brands {
    p {
      font-weight: 300;
      margin-bottom: 24px;

      @include breakpoint (lg) {
        margin-bottom: 32px;
      };
    }

    ul li + li {
      margin-top: 24px;
    }
  }

  .footer-nav {
    @include breakpoint (ltmd) {
      & + .footer-nav {
        margin-top: 16px;
      }
    };

    ul li {
      & + li {
        margin-top: 16px;
      }

      a {
        color: $near-black;
        background: linear-gradient(to right, $dark-blue 0%, $dark-blue 100%) no-repeat 0px 100% / 0 1px;
        transition: $transition2;

        &:hover {
          color: $dark-blue;
          background-size: 100% 1px;
        }
      }
    }
  }

  .footer-bottom {
    //border-top: 1px solid $border-grey;
    margin-top: 48px;
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include breakpoint (ltlg) {
      flex-direction: column;
    };
    p {
      margin-bottom: 0;
    }
  }

  .social-nav {
    display: flex;
    align-items: center;

    li {
      position: relative;

      & + li {
        margin-left: 16px;
      }

      a {
        @extend .button;
        padding: 0;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        flex-direction: column;

        &::before {
          background: $turquois;
          border-radius: calc(50% - 1px);
        }

        .sr-only {
          display: none;
        }

        img {
          max-width: 24px;
        }

        &:hover,
        &.hover,
        &:focus-within {
          border-color: $turquois;
          box-shadow: 0 6px 30px 0 rgb(0 0 0 / 20%);
          transform: scale(1.2);
        }
      }

      .social-nav-tooltip {
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
        padding: 2px 12px;
        font-size: 12px;
        font-weight: 500;
        white-space: nowrap;
        border-radius: 50px;
        background-color: $near-white;
        box-shadow: $shadow;
        pointer-events: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        opacity: 0;
        transition: $transition2;
      }

      a:hover + .social-nav-tooltip {
        top: -100%;
        opacity: .9;
      }
    }
  }
}

// --- Sections ---

.content-top {
  margin-bottom: 80px;
  font-size: 20px;
  p {
    font-weight: 600;
    font-size: 22px;
  }
}

@include breakpoint (ltlg) {
  .form {
    padding-left: 40px;
  }
}

.section--powered {
  margin: 144px 0;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @include breakpoint (ltlg) {
    margin: 112px 0;
  }

  p {
    margin-bottom: 24px;
  }
}
